import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config'
import { urlConstants } from '../../../constants/urlConstants'

export const leadsApi = createApi({
    reducerPath: 'leadsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cp-admin-token')}`)
            return headers
        }
    }),
    
    tagTypes: ['leads'],
    endpoints: (builder) => ({
        getLeads: builder.query<any, any>({
            query: ({searchString,page}) => ({
                url:urlConstants.LEAD,
                method: 'GET',
                params:{
                    searchString: searchString,
                    page:page
                }
            }),
            providesTags: ['leads']
        }),
        createLead: builder.mutation<any, void>({
            query: (data) => ({
                url: urlConstants.LEAD_ADD_BY_ADMIN,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['leads']
        }),
        getCreatorsDropdown: builder.query<any, void>({
            query: () => ({
                url: urlConstants.LEADS_DROPDOWN,
                method: 'GET',
            }),
            providesTags: ['leads']
        }),
    })

})

export const { useGetLeadsQuery, useCreateLeadMutation, useGetCreatorsDropdownQuery} = leadsApi