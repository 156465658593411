import React, { Suspense } from 'react';
import logo from './logo.svg';
// import './App.css';
import './index.scss';
import './scss/button.scss';
import Loading from "./pages/miscellaneous/loading/index"
import { Route, Routes } from 'react-router-dom';
import {PATH} from './routes/path'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
// const Login = React.lazy(() => import("./pages/entry/login/index"));
import CustomAlert from './pages/common/customAlert';
import { useAppSelector } from './base/hooks/hooks';



const CpLayout=React.lazy(()=>import("./layouts/cpLayout/index"))
const Home = React.lazy(()=>import('./pages/home/index'))
const WelcomScreen = React.lazy(()=>import('./pages/welcomeScreen/welcomescreen'))
const Login = React.lazy(()=>import('./pages/login/login'))
const SignUp =React.lazy(()=>import('./pages/signup/signup'))
const ChannelPartner = React.lazy(()=>import('./pages/channelPartner/channelPartner'))
const Projects = React.lazy(()=>import('./pages/projects/projects'))
const UserUpload = React.lazy(()=>import('./pages/userUpload/userUpload'))
const NotFound = React.lazy(() => import("./pages/miscellaneous/notFound/index"))
const Leads = React.lazy(() => import("./pages/leads/leads"))
const ReportList = React.lazy(() => import("./pages/reportList/reportList"))



function App() {
  const { showAlert } = useAppSelector((state: any) => state.AlertMessaegReducer)
  
  return (
    <div className="App">
        {showAlert.showModel||showAlert.deleteAlert?<CustomAlert />:null}   

       <Suspense fallback={<Loading/>}>
        <Routes>
        <Route path="*" element={<NotFound />} />
          <Route path={PATH.WELCOMESCREEN} element={<WelcomScreen />} />
          <Route path={PATH.LOGIN} element={<Login />} />
          <Route path={PATH.REGISTER} element={<SignUp/>}/>

          <Route element={<CpLayout/>}>
            <Route path={PATH.BANNER_TYPE} element={<Home />} />
            <Route path={PATH.CHANNEL_PARTNER} element={<ChannelPartner />} />
            <Route path={PATH.ADD_PROJECTS} element={<Projects />} />
            <Route path={PATH.USER_UPLOAD} element={<UserUpload />} />
            <Route path={PATH.LEADS} element={<Leads />} />
            <Route path={PATH.REPORT} element={<ReportList />} />
            <Route path={PATH.ADD_LEADS} element={<Leads />} />
          </Route>
          
        </Routes>
       </Suspense>
    </div>
  );
}

export default App;
